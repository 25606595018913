@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800);


:root {
    --native-color: #E2049A;
    --main-color: #E2049A;
    --alt-color: #26F980;
    --alt-color2: #FEF200;
    --alt-color3: #12B1FF;
    --alt-color4: #E20404;
    --base-gray: #13202D;
    --light-text: #ffffffab;
    --notif-accent: #E20404;

    --frostedBackground: rgba(40, 40, 40, 0.3);
    --darkFrostedBackground: rgb(0 0 0 / 53%);
    --filledBackground: #0C1016;
    --lightFilledBackground: #32373D;
    --lightFrostedBackground: #32373d45;

    --frostedBorder: 1px solid rgba(255, 255, 255, 0.12);
    --filledBorder: 3px solid var(--main-color);
    --thickFilledBorder: 4px solid var(--main-color);
    --goldThickFilledBorder: 4px solid var(--alt-color2);
      
    --lightShadow: 0px 0px 19px 4px var(--main-color);
    --deepShadow: 0px 1px 44px 8px var(--main-color);
    --goldLightShadow: 0px 0px 19px 4px rgba(254, 242, 0, 0.57);

    --px13: 0.813em;
    --px14: 0.875em;
    --px15: 0.9375em;
    --px16: 1em;
    --px17: 1.0625em;
    --px18: 1.125em;
    --px19: 1.1875em;
    --px20: 1.25em;
}

* {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", sans-serif !important;
    color: white;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;

}

a {
    text-decoration: none;
}


.visible-flex {
    display: flex;
}

#no-payout{
    color:var(--light-text) !important;
    padding: 9px 18px;
        background-color: var(--lightFrostedBackground) !important;
        pointer-events: none !important;
        font-weight: 700;
        font-size: var(--px13);
        border-radius: 26px;
        border: none;
        width: 165px;
        cursor: none !important;
        pointer-events: none !important;
}


.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.vertical-divider {
    width: 2px;
    ;
}

.v-core {
    width: 1px;
    height: 260px;
    background-color: #ffffff21;
    margin-top: -20px;
}

.content-body {
    width: 100%;
    flex: 1;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--frostedBackground);
    border: none;
    border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #666666;
    border-radius: 3px;


}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c5c5c5;
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.visible {
    display: block !important;
}

.hidden {
    display: none !important;
}

.page-title {
    font-size: 44px;
    text-transform: uppercase;
    margin-top: 32px;
    position: sticky;
    top: 224px;
}

#root {

    padding: 0px 75px;
    height: -moz-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
}

.global-padding {
    padding: 0px 75px;
}

.lg-footer-container {
    width: 100%;
    padding: 0px 0px;
}

.frosted-title {
    position: absolute;
    font-size: 166px;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-text-stroke-width: 6px;
    filter: opacity(0.05);
    color: white;
    text-align: right;
    line-height: 186px;
    max-width: 1130px;
    right: 58px;
    transition: 0.2s ease;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--base-gray);

}



/*-----------------------------Component CSS-----------------------------*/






.login-container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}

.login-container>p {
    font-size: var(--px14);
    font-weight: normal;
    color: var(--light-text);
    width: 100%;
    text-align: center;
}

.login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px 30px;
    border-radius: 8px;
    gap: 13px;
    cursor: pointer;
    border: solid 3px white;
    transition: 0.2s ease;

}



.login-btn>img {
    width: 29px;
    object-fit: scale-down;
    transition: 0.2s ease;
}

.login-btn>span {
    font-size: var(--px14);
    font-weight: 700;
    color: black;
    transition: 0.1s ease;
    transition-delay: 0.1s;
}

.login-btn:hover {
    background-color: transparent;
    border: solid 3px var(--main-color);
    box-shadow: 0px 0px 19px 4px rgba(255, 0, 170, 0.57);
    ;
}

.login-btn:hover>span {
    color: white;
}

.login-btn:hover>img {}

.login-btn:active {
    opacity: 0.6;
}







/*-----------------------------Universal Footer CSS-----------------------------*/

.univ-footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    align-items: center;
}

.univ-footer>p {
    font-size: var(--px13);
}

.univ-footer>p>span {
    font-size: var(--px14);
    color: var(--light-text);
    margin-left: 10px
}

.early-access-notice {
 
    border: var(--frostedBorder);
    padding: 6px 15px;
    border-radius: 6px;

}

.early-access-notice>p {
    font-size: 14px;
    color: white;
    ;
}

.early-access-notice>p>span {
    font-size: 14px;
    color: var(--light-text);
}

/*-----------------------------Navbar CSS-----------------------------*/


.navbar {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    box-sizing: border-box;
    justify-content: space-between;
    margin: 40px 0px 0px 0px;
    background-color: transparent;
    background-size: cover;
    border-radius: 10px;
    z-index: 150;
    ;
    position: sticky;
    top: 40px;
 
    gap: 40px;
    max-width: 1300px;
}

.left-nav {
    display: flex;
    align-items: center;
    gap: 50px;
    position: relative;
    z-index: 1;
    padding-left: 0px;
}

.navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.nav-profile {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
}

.navbar-button {
    display: flex;
    background-color: transparent;
    border: solid 3px var(--native-color);
    justify-content: center;
    align-items: center;
    padding: 7px 25px;
    border-radius: 6px;
    box-shadow: 0px 0px 19px 3px rgba(255, 0, 170, 0.57);
    gap: 8px;
    cursor: pointer;
    border-radius: 7px;
    transition: 0.2s ease;
}

.navbar-button:hover {
    box-shadow: 0px 0px 19px 7px rgba(255, 0, 170, 0.77);
}

.navbar-button>i {
    font-size: 1.2rem;
    color: white;
}

.nav-background {
    position: absolute;
    border-radius: 10px;
    width: 100%;
    height: 90px;
    object-fit: cover;

}

.navbar-button>span {
    font-size: var(--px15);
    font-weight: 600;
}

.green-outline {
    border: solid 3px var(--alt-color) !important;
    box-shadow: 0px 0px 19px -3px var(--alt-color) !important;
}

.green-outline:hover {
    box-shadow: 0px 0px 19px 7px rgba(0, 255, 0, 0.77) !important;
}

.yellow-outline {
    border: solid 3px var(--alt-color2) !important;
    box-shadow: 0px 0px 19px -3px var(--alt-color2) !important;
}

.yellow-outline:hover {
    box-shadow: 0px 0px 19px 7px rgba(255, 255, 0, 0.77) !important;
}

.blue-outline {
    border: solid 3px var(--alt-color3) !important;
    box-shadow: 0px 0px 19px -3px var(--alt-color3) !important;
}

.blue-outline:hover {
    box-shadow: 0px 0px 19px 7px rgba(0, 255, 255, 0.77) !important;
}

.disabled-nav {
    opacity: 0.1 !important;
    cursor: default;
}

@media only screen and (max-width: 1470px) {
    .navbar {
        justify-content: space-between;
    }
}

/*-----------------------------Profile Chip CSS-----------------------------*/

.profile-chip-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0px 0px 0px 45px

}

.profile-chip {

    background-color: var(--darkFrostedBackground);
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    padding: 10px 18px;
    border-radius: 8px;
    gap: 10px;
    transition: 0.2s ease;
    cursor: pointer;
    min-width: 190px;

}

.profile-chip>img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.profile-chip-info>h3 {
    font-size: var(--px14);
    font-weight: 600;
    margin-bottom: -2px;
    text-transform: capitalize;
}

.profile-chip-info>p {
    font-size: var(--px16);
    font-weight: 600;
    color: var(--main-color);
    display: flex;
    align-items: center;
    gap: 2px;
    text-transform: capitalize;
}

.profile-chip-info>p>span {
    color: var(--main-color);
    font-size: var(--px14);
}

.profile-chip:hover {
    background-color: var(--filledBackground);

}

.profile-chip:hover>div>h3 {}

.profile-chip-container:hover>.profile-pop {
    display: flex;

}

.profile-pop {
    width: 190px;
    margin: 0px 45px;
    position: absolute;
    background-color: var(--filledBackground);
    padding: 13px 11px;
    margin-top: -10px;
    border-radius: 0px 0px 8px 8px;
    display: none;
    z-index: 100;flex-direction: column;
}

.profile-pop>button {
    width: 100%;
    background-color: var(--base-gray);
    padding: 7px 11px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    gap: 7px;
    transition: 0.2s ease;
    transition-delay: 50ms;

}

.profile-pop>button:hover {
    background-color: var(--main-color);
}

.profile-chip-img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.profile-chip-img>svg {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.fairness-btn > a > button {display: flex; ;justify-content: center;align-items: center;background-color: transparent;border: none;cursor: pointer;gap: 7px;}

.fairness-btn > a > button > i {color: var(--light-text);transition: 0.2s ease;}

.fairness-btn > a > button > span {font-size: var(--px16);font-weight: 600;color: var(--light-text);text-transform: capitalize;transition: 0.2s ease;}

.fairness-btn > a > button:hover > i {color: white;}

.fairness-btn > a > button:hover > span {color: white;}

/*-----------------------------Sub Nav Bar CSS-----------------------------*/
.sub-navbar-wrapper {
    display: flex;
    align-items: center;
    max-width: 1300px;
    width: 100%;
    position: sticky;
    top: 152px;
    z-index: 1000;
    margin: auto;
}


.back-title>h1 {


    font-size: 170px;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
    -webkit-text-stroke: 2.5px #ffffff;
    filter: opacity(0.12);
    padding-right: 50px;
    pointer-events: none;
    ;
}

.sub-navbar {
    display: flex;
    gap: 15px;
    border-bottom: var(--frostedBorder);
    width: fit-content;
    height: fit-content;
    align-self: flex-start;
    ;
}

.sub-navbar-item>p {
    font-size: var(--px19);
    font-weight: 600;
    border-bottom: solid 3px transparent;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--light-text);
    transition: 0.2s ease;
    cursor: pointer;
}

.sub-navbar-item:hover>p {
    border-bottom: solid 3px var(--light-text);
    color: var(--light-text);
}

.focused-sub>div>p {
    border-bottom: solid 3px var(--main-color);
    color: var(--main-color);
    transition: 0.1s ease;
}



.sub-navbar:hover>a>.focused-sub>div>p {
    border-bottom: solid 3px transparent;

    color: var(--light-text);
    cursor: default;
    pointer-events: none;

}

.focused-sub>p:hover {
    border-bottom: solid 3px var(--main-color) !important;
    color: var(--main-color) !important;
}



/*-----------------------------Side Toolbar CSS-----------------------------*/

.side-toolbar-content {
    display: flex;
    margin-top: 35px;
}

.side-button>input {
    display: none;
}

.side-buttons {
    border: var(--frostedBorder);
    width: 180px;
    border-radius: 6px;
    height: fit-content;
    padding: 3px 0px;
    min-width: 180px;
    position: sticky;
    top: 319px;
    z-index: 1000;
}

.side-button>label {
    font-weight: 600;
    font-size: var(--px15);
    padding: 8px 15px;
    display: block;
    width: 100%;
    cursor: pointer;
    transition: 0.1s ease;
    border-radius: 4px;
}

.side-button>input:checked~label {
    background-color: var(--frostedBackground);
    outline: var(--filledBorder);
}

.side-content {

    display: flex;
    justify-content: space-between;
    gap: 75px;
    padding-left: 92px;
    height: fit-content;
    overflow-y: auto;
    padding-top: 79px;
    margin-top: -90px;
    border-radius: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.selected-mode {
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    ;
    border-radius: 9px;
    margin-top: -25px;
}

/*-----------------------------Article CSS-----------------------------*/

.article {
    column-count: 2;
}

.article>p {
    padding-bottom: 20px;
}

.floating-title {
    position: sticky;
    top: -59px;
    background-color: #000000db;
    padding: 15px;
    border-radius: 7px;
    background-image: url(/src/assets/navbaracrylic.png);
    backdrop-filter: blur(100px);
    margin: 0px 20px;
    display: none;
    text-align: center;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    border: var(--frostedBorder);
}

/*-----------------------------My Games List CSS-----------------------------*/

.mygames-wrapper {
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    margin-bottom: 5px;
justify-content: flex-start
    ;
}

.game-card {
    display: flex;
    flex-direction: column;
    width: 31%;
    background-image: url(/src/assets/backplates/pinkMyGame.png);
    border: var(--frostedBorder);
    border-radius: 7px;
    padding: 0px 16px;
    padding-top: 16px;
    background-size: cover;
    transition: 0.2s ease;
}

.game-card > p {    padding-bottom: 1rem;
    font-size: var(--px13);
    font-weight: 600;
    border-top: var(--frostedBorder);
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    margin-bottom: 0px;
    height: 43px;}

.game-card-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 11px;
   
}

.game-card-header > h1 {
    margin-bottom: -15px;
}

.game-card-header-left {
    display: flex;
    gap: 11px;
}

.game-track {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border: var(--thickFilledBorder);
    border-radius: 8px;
    font-size: var(--px19);
    box-shadow: var(--lightShadow);
}

.game-card-title {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: center;
}

.game-card-title>h4 {
    font-size: var(--px13);
    font-weight: 600;
    color: var(--light-text);
    margin-bottom: -3px;
}

.game-card-title>p {
    font-size: var(--px14);
    font-weight: 600;
}

.game-card-title>p>span {
    font-weight: 700;
    color: var(--main-color);
    font-size: var(--px16);
}

.game-card-header-right {
    display: flex;
    align-items: center;
    pointer-events: none;
}

.game-card-header-right>p {
    font-size: var(--px14);
    font-weight: 600;
    background-color: var(--lightFrostedBackground);
    padding: 2px 10px;
    border-radius: 12px;
    pointer-events: none;
}

.game-card-body {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    gap: 12px;
    align-items: center;
}

.claim-mode {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 105px;
    gap: 6px;
}

.claim-mode>button {
    padding: 9px 18px;
    background-color: var(--main-color);
    font-weight: 700;
    font-size: var(--px13);
    border-radius: 26px;
    border: none;
    cursor: pointer;
}

.claim-mode>p {
    font-size: 12px;
    color: var(--light-text);
}



.timer {
    display: none
}

.timer>h3 {
    font-size: var(--px13);
}

.timer>h2 {
    font-size: 28px;
    color: var(--main-color);
    font-weight: 700;
    margin-top: -6px;
}








.claim-track {
    border: var(--goldThickFilledBorder);
    box-shadow: var(--goldLightShadow);
}

.claim-card {
    background-image: url(/src/assets/backplates/plainMyGame.png);
}

.claim-theMode>button {
    background-color: var(--alt-color2) !important;
    color: black !important;
}

.claim-price {
    color: var(--alt-color2) !important;
}











.loader-container {
    position: fixed;
    z-index: 1000;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgb(0 0 0 / 84%);
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader {
    width: fit-content;
    height: 80px;
    border: var(--thickFilledBorder);
    animation: dash 1.5s linear infinite;
    background-color: var(--base-gray);
    box-shadow: var(--lightShadow);
    border-radius: 12px;
    padding: 0px 40px;
}

.loader-inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.loader-inner>h3 {
    text-align: center;
    ;
    font-size: var(--px19);
}

.loader-logo>h3 {
    font-size: 34px;
    text-transform: uppercase;
    color: white;
}

.loader-logo>p {
    text-align: center;
    font-size: var(--px20);
    font-weight: 800;
    margin-top: -6px;
    color: var(--main-color);
}

.loader-inner>img {
    width: 20px;
}

.loader-logo-container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none !important;
}








.taken-track {
    border: var(--thickFilledBorder);
    box-shadow: var(--lightShadow);
}

.taken-card {
    background-image: url(/src/assets/backplates/plainMyGame.png);
}



.taken-theMode>button {
    background-color: var(--lightFrostedBackground);
    color: var(--light-text);
    width: 150px;
    cursor: default;
    pointer-events: none
}

.taken-theMode>p {
    text-align: right;
    font-size: 10px !important;
    font-weight: 500;
}

.claim-price {
    color: var(--alt-color2) !important;
}

.taken-timer {
    display: flex;
    flex-direction: column;
}

.waiting-theMode>button {
    background-color: var(--lightFilledBackground);
    color: var(--light-text);
    width: 150px;
    cursor: default;
    pointer-events: none
}

.waiting-timer {
    display: flex;
    flex-direction: column;
}

.waiting-theMode > p {
    text-align: right;
    font-size: 10px;
    font-weight: 500;
}







.notif-container {
    position: fixed;
    bottom: 45px;
    left: 65px;
    width: 287px;
    z-index: 1500;
    display: none;
    transition: 0.2s ease;
    background-color: var(--frostedBackground);
    align-items: center;
    padding: 15px 20px;
    gap: 20px;
    backdrop-filter: blur(58px);
    border: var(--frostedBorder);
    border-radius: 8px;
    justify-content: space-between;
}

.notif {}



.notif-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
    border-bottom: var(--frostedBorder);
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notif-icon>i {
    font-size: 26px;
    color: var(--notif-accent);
}

.notif-title>h2 {
    color: var(--notif-accent);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px;
}

.notif-text>h3 {
    color: white;
    font-size: var(--px15);
    font-weight: 700;
}

.notif-text>p {
    color: white;
    font-size: var(--px13);
    font-weight: 500;
    margin-top: 5px
}

.notif-title>button {
    background-color: transparent;
    border: none;
    height: fit-content;
    display: flex;
    align-items: center;
}

.notif-title>button>i {
    background-color: #ffffff1c;
    padding: 3px;
    border-radius: 50px;
    font-size: 19px;
}

.notif-title>button>i:hover {
    background-color: var(--notif-accent)
}

.visiblefalse {
    display: none;
}

.visibletrue {
    display: block;
}










.block-layout {width: 100%; max-width: 1060px;display: flex; flex-direction: column;}

.block-layout > p {    text-align: center;
    padding: 0px 112px;
    padding-top:22px;
    font-size: var(--px14);
    line-height: 22px;
}

.hero-block {width:100%; background-image: transparent !important;border: var(--frostedBorder);display: flex;padding: 35px;border-radius: 10px;justify-content: space-between;background-size: cover;background-repeat: no-repeat;}

.block-tagline {display: flex;align-items: center;gap:20px}

.block-vert-line {height: 90px; width: 3.5px; background-color: var(--main-color);}

.block-charm {    width: 580px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.block-tagline > h1{ font-size: 120px;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-text-stroke-width: 1.5px;
    filter: opacity(0.07);
    color: white;
    line-height: 113px;
    max-width: 1130px;
    transition: 0.2s ease;
    width: 290px;}

.block-tagline > h1 > p {
    font-size: 14px;
    -webkit-text-stroke-width: 0;
    font-weight: 600;
    text-transform:capitalize;
    line-height:15px
}

.charm-text {width: 270px;}

.charm-text > h2 {
    font-size: 52px;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--main-color);
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    width: 100%;
    line-height: 55px;
}

.charm-text > h4 > span {color: var(--main-color);}

.charm-button {display: flex; gap: 10px;}

.charm-button > button {    background-color: var(--main-color);
    color: white;
    border: none;
    padding: 6.5px 40px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 50px;
    font-size: var(--px13);margin-top: 15px;cursor: pointer;}

.charm-button > :last-of-type {    background-color:var(--darkFrostedBackground);
    color: white;
   
    padding: 6.5px 40px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 50px;
    font-size: var(--px13);
    margin-top: 15px;
    cursor: pointer;}

.charm-text > h2 > span {color: transparent;   -webkit-text-stroke-width: 2px;-webkit-text-stroke: solid; -webkit-text-stroke-color: var(--main-color);font-weight: 800;}

.charm-text > p {width: 100%;
    text-align: center;
    font-size: var(--px13);
    color: var(--light-text);}

.charm-text > h4 {width: 100%; text-align: center;}

.card-two-buttons{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.card-two-buttons > button{
    cursor: pointer;
    border: none;
    border-radius: 26px;
    cursor: pointer;
    font-size: .813em;
    font-size: var(--px13);
    font-weight: 700;
    padding: 9px 18px;
}

.card-two-buttons > .claim-button-card{
    background-color: var(--alt-color2) !important;
    color: black !important;
}


.card-two-buttons > .view-claim-button-card{
    background-color: var(--frostedBackground);
    border: var(--frostedBorder);
}

.card-button-solo{
    width: 165px;
}

.card-button-double{
    width: 125px;
}

@media screen and (max-width: 1000px) {
    .frosted-title {
        filter: opacity(0.025);
    }
}

@media screen and (max-height: 500px) {
    .back-title {
        display: none;
    }
}