.modal-wrapper {
    position: fixed;
    left: 0px;
    width: 100%;
    height: 100vh;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000000;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(26px);
    backdrop-filter: blur(39px);
    z-index: 1001;
}

.modal-container {
    background-image: url(/src/assets/backplates/ModalBackPlate.png);
    width: 908px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 87vh;
    padding: 0px 35px;
    border-radius: 14px;
    overflow-y: scroll;
    max-height: 760px;

}

.modal-header {
    display: flex;
    justify-content: space-between;
    padding: 14px 14px;
    align-items: center;
    border-bottom: var(--frostedBorder);

    transition: 0.2s ease;


}



.modal-header>h1 {
    font-size: var(--px15);
    text-align: center;
}

.modal-close {
    cursor: pointer;
    background-color: transparent;
    border: none;
    position: sticky;
    top: 0px;
}

.modal-close>i {
    background-color: var(--lightFilledBackground);
    padding: 6px;
    border-radius: 60px;
    transition: 0.2s ease;
}

.modal-close>i:hover {
    background-color: var(--main-color);
}

.modal-hero>h2 {
    font-size: 125px;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
    -webkit-text-stroke: 2.5px #ffffff;
    filter: opacity(0.09);

    pointer-events: none;
    text-align: right;
}

.modal-hero>h3 {
    margin-top: -10px;
}

.modal-sticky-bar {
    padding: 22px 0px;
    position: sticky;
    bottom: 0px;
    background-color: #181d2b;
    border-top: var(--frostedBorder);
    margin-top: 36px;
}

.modal-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.legal-agree>input[type="checkbox"] {
    accent-color: var(--main-color);
    transform: scale(1.3);
    cursor: pointer;
}

.legal-agree>label {
    font-size: var(--px16);
    font-weight: 600;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}


.modal-action-btn {
    background-color: var(--main-color);
    padding: 11px 40px;
    font-size: var(--px15);
    color: white;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease;
}

.activeStatusfalse {
    background-color: var(--darkFrostedBackground);
    color: var(--light-text);
    cursor: default;
    pointer-events: none;


}










.legal-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    line-height: 30px;
    margin-top: 11px;
}

.legal-text>p {
    font-size: var(--px14);
    text-align: left;
}








/* -----------------Progress CSS------------------- */




.progress-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.left-gameBlock {
    position: sticky;
    top: 0px;
    height: fit-content;
}

.left-gameBlock>h4 {
    font-size: var(--px19);
    margin-top: 30px;
    margin-bottom: 16px;
}




.member-text {
    display: flex;
    gap: 10px;
    align-items: center;
}

.m-icons>img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50px;
}

.g-block {
    display: flex;
    gap: 21px;
    width: 100%;
}

.compact-gblock {
    flex-direction: column;
    width: 315px;
}

.pricing-block {
    background-color: var(--frostedBackground);
    padding: 14px 18px;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
}

.pricing-block>h5 {
    text-align: center;
    font-size: var(--px15);
}

.pricing-block>p {
    font-size: 12px;
    text-align: center;
}

.reward-block {
    background-color: var(--lightFrostedBackground);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 11px 0px;
    padding: 11px 0px;
    border-radius: 5px;
}

.reward-block>h3 {
    color: var(--native-color);
}

.reward-block>h2 {
    font-size: 34px;
}

.timeline {
    width: 100%;
    padding: 17px 20px;
    background-color: var(--frostedBackground);
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 7px;
}

.progress-bar {
    display: flex;
    justify-content: space-between;
}

.progress-bar>progress {
    accent-color: var(--native-color);
    color-scheme: dark;
    width: 345px;
}

.progress-bar>p {
    font-size: var(--px13);

    font-weight: 600;
    text-align: center;
}

.progress-bar>p:last-of-type {}

.progress-chip {
    font-weight: 700;
    padding: 4px 11px;
    border-radius: 50px;
    background-color: var(--lightFrostedBackground);
    font-size: var(--px14);
}

.progress-chip>span {
    color: var(--native-color);
}

.timeline-title {
    display: flex;
    justify-content: space-between;
}

.timeline-title>h3 {

    font-size: var(--px15);
}

.timeline-title>h3>span {
    color: var(--native-color);
}

.right-gameBlock>h4 {
    font-size: var(--px19);
    margin-top: 30px;
    margin-bottom: 16px;
}

.team-member {
    display: flex;
    justify-content: space-between;
    background-color: var(--lightFrostedBackground);
    padding: 15px 15px;
}

.team-member>img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.team-block {
    background-color: var(--frostedBackground);
    padding: 0px 15px;
    padding-top: 15px;
    border-radius: 6px 6px 0px 0px;
    padding-bottom: 15px;
}

.m-text {
    height: fit-content;
}

.lg-modal-close {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    left: 0px;
    top: 0px;
    border: none;
}

.m-text>h3 {
    font-size: var(--px14);
    color: var(--alt-color4);
}

.m-text>h2 {
    font-size: var(--px15);
    font-weight: 600;
    overflow: hidden;
    max-width: 124px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.member-rewards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.member-rewards>h2 {
    font-size: var(--px14);
    font-weight: 700;
}

.block-expand {
    background-color: var(--frostedBackground);
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 6px 6px;
}

.block-expand>button {
    cursor: pointer;
    margin-top: -2px !important;
}

.member-rewards>h3 {
    font-size: var(--px14);
    font-weight: normal;
}

.member-stats {
    display: flex;
}

.member-kills {
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-member:first-of-type {
    border-radius: 7px 7px 0px 0px;
}

.team-member:last-of-type {
    border-radius: 0px 0px 7px 7px;
}

.member-kills>img {
    height: 26px;
    width: 26px;
    object-fit: scale-down;
}

.member-kills>h2 {
    font-size: 19px;
    ;
}

.block-expand>button {
    display: flex;
    align-items: center;
    background-color: var(--lightFrostedBackground);
    border: none;
    border-radius: 50px;
    padding: 5px 19px;
    margin: 13px;
    font-weight: 600;
}

.member-eliminated {
    opacity: 0.5;
}

.code-view>textarea {
    background-color: var(--frostedBackground);
    width: 550px;
    border: var(--frostedBorder);
    height: 200px;
    padding: 15px;
    border-radius: 3px;
    font-size: var(--px14);
    line-height: 25px;

}





.battle-timeline {
    background-color: var(--frostedBackground);
    width: 470px;
    padding-bottom: 1px;
    margin-bottom: 35px;
    border-radius: 10px;
}

.b-timeline-header {
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.b-timeline-header>h5 {
    text-align: center;
    font-size: var(--px14);
}

.b-time-divider {
    display: flex;
    ;
}

.b-time-strip {
    display: flex;
    justify-content: space-between;
    background-color: var(--lightFrostedBackground);
    padding: 10px 17px;
    border-radius: 9px;
}

.b-time {
    display: flex;
    align-items: center;
    gap: 13px;
}

.b-time>span {
    font-size: var(--px19);
    font-weight: 600;
}

.b-text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 3px;
}

.b-text>h6 {
    font-size: var(--px15);
    font-weight: 600;
    overflow: hidden;
    width: 106px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.b-text>p>h6 {
    font-size: var(--px15);
    font-weight: 600;
    overflow: hidden;
    width: 106px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: -6px;
}

.eliminated {
    font-weight: 600;
    color: var(--alt-color4);
}

.img-grid>img {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.img-grid {
    display: flex;
    width: 40px;
    height: 40px;
}

.img-grid-item {
    width: 50%;
    justify-content: space-around;
    height: 100%;
}

.img-grid-item>img {
    width: 100%;
    object-fit: cover;
    padding: 1px;
    height: 100%;
}


.img-grid:first-child {
    border-radius: 51px 0px 0px 50px;
    ;
}

.img-grid .img-grid-item:first-of-type>img {
    border-radius: 51px 0px 0px 50px;
}

.img-grid .img-grid-item:last-of-type>img {
    border-radius: 0px 51px 50px 0px;
}

.b-text>details>summary {
    margin-bottom: 5px;
    display: block;
}

.b-text>details>div>p {
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    max-width: 124px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.b-text>p>span {
    font-weight: 600;
    text-decoration: underline;
}

.b-text>p>span:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--main-color);
}

.b-text>p {
    margin-top: -3px;
    font-size: var(--px14);
    width: 108px;
}

.b-time>img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50px;
}

.b-time-divider {
    width: 1px;
    height: 40px;
    background-color: #7b7b7b45;
}

.day-grouper {
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding: 0px 25px;
    margin-bottom: 30px;
}

.day-grouper:first-of-type {
    margin-top: -43px;
}

.day-grouper>h5 {
    width: 100%;
    text-align: center;
    font-size: var(--px15);
}

.empty-point {
    margin-left: 52px;
}



/*----------SHOP---------*/

.product {
    display: flex;
    gap: 40px;
    margin-top: 20px;
}

.product-image>img {
    width: 448px;
    object-fit:scale-down;
    border: var(--frostedBorder);
    border-radius: 10px;
}

.product-title>h2 {
    font-size: 33px;
    font-weight: 700;
    color: var(--light-text);
    margin-bottom: 20px;
}

.product-title>h2>span {
    color: var(--main-color);
}

.product-input {
    border-bottom: var(--frostedBorder);
    padding-bottom: 10px;
}

.product-input>input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--darkFrostedBackground);
    border-radius: 7px;
    padding: 10px 18px;
    font-size: var(--px15);
    border-bottom: 3px solid transparent;
    transition: 0.2s ease;
    font-weight: 600;
    color: var(--main-color);
}

.product-input>input:focus {
    border-bottom: var(--filledBorder);
    outline: none;
    border-radius: 7px 7px 0px 0px;
   
}

.product-input>label {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
}

.mini-btn {
    width: 100%;
}

.product-action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.product-checkout {
    position: sticky;
    bottom: 0px;
    margin-top: 200px;
}

.product-checkout>p {
    font-size: 12px;
    margin-top: 15px;
    color: white;
    font-weight: 600;
    padding-bottom: 5px;
}

.product-input>p {
    font-size: 12px;
    margin-top: 10px;
    color: var(--light-text);

}

.product-checkout>p>button {
    color: var(--main-color);
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: 600;
}

.product-checkout>p>button:hover {
    text-decoration: underline;
}